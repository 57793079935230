import React, {Fragment, useCallback, useState, useMemo, useRef, useEffect} from 'react';
import {Placement, ToolboxDrawer, ToolboxSection} from '../../../componentsLib/Popovers';
import {Column, Field, Fields, Row, Table, TableCell, TableRow, usePageContext} from '../../../componentsLib/Layout';
import {SalesDoc, SalesDocItem} from '../Models/SalesDoc';
import {BodyText, CaptionText, HeadingText} from '../../../componentsLib/Text';
import {Button, ButtonTheme, DescriptiveRadio, DescriptiveRadioGroup, ImageThumbnail, TextInput, ToolTip} from '../../../componentsLib/Basic';
import {registerGlobalStyle} from '../../../theme';
import {asCurrencyStringCommaSeparated, boolValueFromEvent, formatAddressTwoLines, fullName, inputNameFromEvent, isValidField, MaxFileSize, valueFromEvent} from '../../../utils';
import {Chip} from '../../../componentsLib/Chips';
import {stripHTML} from '../../../utils/stripHtml';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Info as InfoIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material';
import {CartSubSections, SalesDocSections} from '../Components/SalesDocSection';
import {ImageAndFileUploadManager} from '../../../componentsLib/DragDrop';
import {useSalesDocCartState} from '../State/useSalesDocCartState';
import {Collapse} from '@mui/material';
import Lottie from 'react-lottie';
import successAlertIcon from '../../../assets/lotties/success-alert-icon.json';
import {PayOnline} from '../../../componentsHoops/Stripe/PayOnline';
import {useComment} from '../Hooks';
import {useSalesDocAssociateFiles} from '../../../hooks/api';
import {groupBy, isEqual} from 'lodash';

const THUMBNAIL_SIZE = 40;

registerGlobalStyle('.shopping-cart-drawer', (theme) => ({
  position: 'absolute',
  '.toolbox-section': {
    '.text-heading:first-of-type': {
      fontSize: theme.fontSize(24),
      fontWeight: theme.typography.bold,
    }
  },
  '.shopper-edit-details': {
    '.fields': {
      paddingBottom: theme.spacing(2),
      columnGap: theme.spacing(2)
    },
    '.field-heading': {padding: theme.spacing(2, 0, 0)},
    '.descriptive-group>.descriptive-row': {
      justifyContent: 'start',
      padding: theme.spacing(1, 0, 2)
    }
  },
  '.shopping-cart-content': {
    zIndex: 0,
    '.variant-table': {
      '.text-body': {margin: theme.spacing(.5, 1.75, .5, 1)},
      '.total-row': {background: theme.colors.background.empty}
    },
    '.show-hide-details-buttons': {padding: theme.spacing(2, 0)},
    [theme.media.phoneOrTablet()]: {'.variant-table-column': {overflow: 'auto'}},
    '.add-on-chips': {gap: theme.spacing(1)},

  },
  '.shopping-cart-title': {'.row-divider': {marginBlock: 'unset'}},
  '.shopping-cart-table, .shopper-uploaded-image': {
    'svg': {
      color: theme.colors.palette.greyLighter,
      cursor: 'pointer'
    },
    '.image-thumbnail': {
      alignItems: 'start',
      'img, canvas': {
        borderRadius: theme.shape.borderRadius,
        border: `1px solid ${theme.colors.border.transparent}`,
      },
      'svg': {cursor: 'default'},
    }
  },
  '.shopper-uploaded-image': {
    '.image-thumbnail': {
      alignItems: 'center',
      'canvas': {boxShadow: theme.shadows.paper}
    }
  },
  '.shopping-cart-table': {
    'svg': {marginLeft: theme.spacing(1)},
    '.table-data-cell:first-of-type': {
      width: 120,
      paddingRight: theme.spacing(2),
      [theme.media.phoneOrTablet()]: {
        width: 80,
        paddingRight: theme.spacing(1)
      },
    },
    '.variant-details': {
      verticalAlign: 'bottom',
      '.table-data-cell:first-of-type': {
        verticalAlign: 'top',
        '.image-thumbnail': {
          width: 120,
          height: 120,
          [theme.media.phone()]: {
            width: 80,
            height: 80
          },
        }
      },
      '.variant-details-text': {verticalAlign: 'top'},
    },
  },
  '.chip-container': {
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(1)
  },
  '.shopper-details': {
    position: 'relative',
    '.shopper-details-address-column': {justifyContent: 'end'},
    '.text-caption, .shopper-contact-autocomplete-label': {
      fontSize: '0.875rem',
      color: theme.colors.text.medium
    },
    '.text-body': {fontSize: '0.875rem'},
    [theme.media.phoneOrTablet()]: {
      flexDirection: 'column',
      rowGap: theme.spacing(1.5)
    },
  },
  '.image-upload-manager': {
    width: '100%',
    '.file-select:first-child': {height: 150}
  },
  '.selectable-chip': {
    background: theme.colors.background.white,
    border: `1px solid ${theme.colors.border.dark}`,
    overflow: 'visible',
    position: 'relative',
    width: 'fit-content',
    '.text-body': {fontSize: theme.fontSize(14)},
    'svg': {color: theme.colors.text.medium},
    '&:hover': {
      border: `1px solid ${theme.colors.border.main}`,
      '.selection-action-buttons': {opacity: 1}
    },
    '&.selected': {
      background: theme.colors.palette.green,
      border: `1px solid ${theme.colors.border.transparent}`,
      color: theme.colors.text.white,
      'svg': {color: theme.colors.text.white},
    },
    '&.disabled': {pointerEvents: 'none'}
  },
  '.toolbox-drawer-content': {padding: theme.spacing(2, 0),},
  '.shopping-cart-footer': {
    columnGap: theme.spacing(1),
    '.shopping-cart-action-buttons': {
      paddingTop: theme.spacing(1),
      '.button': {width: '100%'},
      '.busy-spinner': {width: 'auto'},
      '.button-theme:has(> .button)': {width: '100%'},
    },
    [theme.media.phoneOrTablet()]: {
      '.shopping-cart-action-buttons': {
        flexWrap: 'wrap',
        '.button-theme:has(> .button)': {
          whiteSpace: 'nowrap',
          minWidth: `calc(50% - ${theme.spacing(1)})`,
          width: 'unset',
          flexGrow: 1
        }
      },
      '.row-gap': {rowGap: theme.spacing(2)}
    },
    '.tooltip-container': {width: '100%'},
    '.shopping-cart-totals': {
      paddingBottom: theme.spacing(1),
      width: '100%',
      textAlign: 'right',
      'div': {
        display: 'flex',
        justifyContent: 'end',
        '.table': {
          '.table-row': {
            'td:last-of-type': {paddingLeft: theme.spacing(3)},
            'td': {
              padding: theme.spacing(1, 0),
              '.text-body': {
                textAlign: 'right',
                fontSize: 20
              },
            },
          },
        },
      }
    }
  },
  '.complete-order-totals': {
    paddingBottom: theme.spacing(1),
    width: '100%',
    textAlign: 'right',
    'div': {
      display: 'flex',
      justifyContent: 'end',
      '.text-body': {textAlign: 'right',},
      '.table': {
        '.table-row': {
          '&.complete-order-total-row': {'.text-body': {fontSize: theme.fontSize(20)}},
          'td:last-of-type': {paddingLeft: theme.spacing(3)},
          'td': {padding: theme.spacing(1, 0)},
        },
      },
    }
  },
  '.pay-online-section': {'.pay-online': {'.payment-charges': {'h6': {fontSize: theme.fontSize(14)}}}},
  '.order-confirmation-success': {
    '.text-heading + .text-body': {
      fontWeight: theme.typography.normal,
      color: theme.colors.text.medium,
      fontSize: theme.fontSize(24)
    },
    '.text-body': {fontSize: theme.fontSize(16)}
  },
  '.start-new-order-footer': {
    '.row': {columnGap: theme.spacing(.5)},
    '.text-body, .button': {fontSize: theme.fontSize(16)}
  },
}));

export function ShoppingCartDrawer({refetchSalesDoc}) {
  const {selection, cartDoc, cartOpen, clearCart, salesDoc, saveCart, setCartOpen, setSelection, uploadCache} = usePageContext();

  const handleGoToMyCart = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: null}));
  }, [setSelection]);

  const handleGotoShopperDetails = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: CartSubSections.shopperDetails}));
  }, [setSelection]);

  const handleGoToAddFilesAndNotes = useCallback(async () => {
    if (salesDoc._id === cartDoc._id) {
      const res = await saveCart();
      if (res) {
        setSelection((prev) => ({...prev, subSection: CartSubSections.shopperUpdateFilesAndNotes}));
      }
    } else {
      setSelection((prev) => ({...prev, subSection: CartSubSections.shopperUpdateFilesAndNotes}));
    }
  }, [cartDoc, salesDoc, saveCart, setSelection]);

  const handleGoToCompleteOrder = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: CartSubSections.completeOrder}));
  }, [setSelection]);

  const handleGoToPayLater = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: CartSubSections.orderConfirmed}));
  }, [setSelection]);

  const handleGotoPayNow = useCallback(() => {
    setSelection((prev) => ({...prev, subSection: CartSubSections.payNow}));
  }, [setSelection]);

  const handleClearCart = useCallback(() => {
    clearCart();
    setCartOpen(false);
    uploadCache.clearCache();
    refetchSalesDoc();
    setSelection((prev) => ({...prev, subSection: null}));
  }, [clearCart, setCartOpen, setSelection, refetchSalesDoc, uploadCache]);

  return (
    <ToolboxDrawer
      className={'shopping-cart-drawer'}
      open={cartOpen}
      onOpenChange={setCartOpen}
      width={800}
      noBack
      backdrop
      drawOpenIcon={<ShoppingCartIcon style={{color: salesDoc.template.cartIconColor}} />}
      drawCloseIcon={<ShoppingCartIcon style={{color: salesDoc.template.cartIconColor}} />}
    >
      {cartDoc &&
        <Column>
          {selection.groupId &&
            <AddToCartSection />
          }
          {!selection.groupId && !selection.subSection &&
            <>
              <ShoppingCartSection heading={'Your Cart'} isEditable={true} showTotals={false} />
              <ShoppingCartFooterSection onClickNext={() => cartDoc.customerId ? handleGoToAddFilesAndNotes() : handleGotoShopperDetails()} />
            </>
          }
          {selection.subSection === CartSubSections.shopperDetails &&
            <ShopperDetailsSection onClickBack={handleGoToMyCart} onClickNext={handleGoToAddFilesAndNotes} />
          }
          {selection.subSection === CartSubSections.shopperUpdateFilesAndNotes &&
            <UpdateFilesAndNotesSection onClickBack={() => cartDoc.customerId ? handleGoToMyCart() : handleGotoShopperDetails()} onClickNext={handleGoToCompleteOrder} />
          }
          {selection.subSection === CartSubSections.completeOrder &&
            <>
              <ShoppingCartSection heading={'Complete Order'} isEditable={true} showTotals={true} />
              <CompleteOrderFooterSection onClickBack={handleGoToAddFilesAndNotes} onClickPayLater={handleGoToPayLater} onClickPayNow={handleGotoPayNow} />
            </>
          }
          {selection.subSection === CartSubSections.payNow &&
            <PayNowSection onClickBack={handleGoToCompleteOrder} />
          }
          {selection.subSection === CartSubSections.orderConfirmed &&
            <>
              <OrderConfirmedSection />
              <ShoppingCartSection heading={''} isEditable={false} showTotals={true} />
              {salesDoc.documentType === SalesDoc.Type.SALESSTORE &&
                <ToolboxSection noSep stickyFooter className={'start-new-order-footer'}>
                  <Row divider />
                  <Row justifyRight>
                    <BodyText text={'Want to start a new order?'} />
                    <Button actionPrimary text={'Click here'} onClick={handleClearCart} />
                  </Row>
                </ToolboxSection>
              }
            </>
          }
        </Column>
      }
    </ToolboxDrawer>
  );
}

const ShoppingCartSection = ({heading, isEditable, showTotals}) => {
  const {cartDoc, setSelection, setCartOpen} = usePageContext();

  const variantItemsInCart = cartDoc.getGroupIds().map((groupId) => cartDoc.getFirstProductVariantItemInGroup(groupId))?.filter(Boolean);

  const handleEditGroup = useCallback((item) => {
    const groupId = cartDoc.priceCalculator.itemMap.getStoreId(item.groupId);
    const variantId = cartDoc.priceCalculator.itemMap.getStoreId(item.variantId);

    // Setting selection here will send cart back to the item
    setSelection({groupId, section: SalesDocSections.product, itemId: item.itemId, variantId});
  }, [cartDoc.priceCalculator.itemMap, setSelection]);

  const handleDeleteGroup = useCallback((e, groupId) => {
    e.stopPropagation();
    cartDoc.deleteGroup(groupId);
  }, [cartDoc]);

  return (
    <ToolboxSection noSep className={'shopping-cart-content'} closable heading={heading} underSep onClose={() => setCartOpen(false)}>
      <Column gap>
        <Table className={'shopping-cart-table'}>
          <TableRow>
            <TableCell colSpan={2}>
              <BodyText dark>Item</BodyText>
            </TableCell>
            <TableCell alignRight>
              <BodyText dark>Quantity</BodyText>
            </TableCell>
            <TableCell alignRight>
              <BodyText dark>Total</BodyText>
            </TableCell>
            <TableCell />
          </TableRow>
          {variantItemsInCart.map((item) => (
            <Fragment key={item.groupId}>
              <TableRow className={'variant-details'}>
                <TableCell rowSpan={2}>
                  <Row alignCenter>
                    <ImageThumbnail imageUrl={item.images?.[0]} />
                  </Row>
                </TableCell>
                <TableCell>
                  <Row alignCenter>
                    <BodyText dark text={item.name} />
                  </Row>
                </TableCell>
                <TableCell alignRight>
                  <Row justifyRight alignCenter>
                    <BodyText>{cartDoc.summary.quantities[item.groupId].quantity}</BodyText>
                    {isEditable &&
                      <EditIcon onClick={() => handleEditGroup(item)} />
                    }
                  </Row>
                </TableCell>
                <TableCell alignRight>
                  <BodyText className={'currency-prefix'}>{asCurrencyStringCommaSeparated(cartDoc.summary.summary[item.groupId].totalRevenue)}</BodyText>
                </TableCell>
                <TableCell alignRight>
                  {isEditable &&
                    <DeleteIcon onClick={(e) => handleDeleteGroup(e, item.groupId)} />
                  }
                </TableCell>
              </TableRow>
              <TableRow key={item.groupId} className={'variant-details-text'}>
                <TableCell colSpan={5}>
                  <ShoppingCartItemDetails groupId={item.groupId} />
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </Table>
      </Column>

      {showTotals &&
        <Column gap>
          <Row divider />
          <div className={'complete-order-totals'}>
            <div>
              <Table>
                <TableRow>
                  <TableCell text={'Subtotal'} />
                  <TableCell>
                    <BodyText dark className={'currency-prefix'}>{asCurrencyStringCommaSeparated(cartDoc.summary.subTotal)}</BodyText>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell text={'Tax'} />
                  <TableCell>
                    <BodyText dark className={'currency-prefix'}>{asCurrencyStringCommaSeparated(cartDoc.summary.taxTotal)}</BodyText>
                  </TableCell>
                </TableRow>
                <TableRow className={'complete-order-total-row'}>
                  <TableCell text={'Total'} />
                  <TableCell>
                    <BodyText dark className={'currency-prefix'}>{asCurrencyStringCommaSeparated(cartDoc.summary.total)}</BodyText>
                  </TableCell>
                </TableRow>
              </Table>
            </div>
          </div>
        </Column>
      }
    </ToolboxSection>
  );
};

const ShoppingCartItemDetails = ({groupId}) => {
  const {cartDoc, salesDoc} = usePageContext();
  const [showItemDetails, setShowItemDetails] = useState(false);
  const {variantDetailsText, decorationDetailsText} = useMemo(() => {
    const variants = cartDoc.items.filter((item) => item.quantity && item.type !== SalesDocItem.Type.GROUP_PLACEHOLDER && item.type === SalesDocItem.Type.VARIANT && item.groupId === groupId).sort((a, b) => (a.color < b.color));
    const decorations = cartDoc.items.filter((item) => item.quantity && item.type !== SalesDocItem.Type.GROUP_PLACEHOLDER && item.type !== SalesDocItem.Type.VARIANT && item.groupId === groupId && item.showItem).sort((a, b) => (a.name < b.name));
    return {
      variantDetailsText: variants.map((item, index) => (<Fragment key={item.itemId}>{item.color + '/' + item.size + ' x '}<b>{item.quantity}</b>{(index < variants.length - 1 || decorations.length > 0) ? ', ' : '.'}</Fragment>)),
      decorationDetailsText: decorations.map((item, index) => (<Fragment key={item.itemId}>{stripHTML(item.description) + ' x '}<b>{item.quantity}</b>{index < decorations.length - 1 ? ', ' : '.'}</Fragment>))
    };
  }, [cartDoc, groupId]);

  const handleShowDetails = useCallback(() => {
    setShowItemDetails((prev) => (!prev));
  }, []);

  return (
    <>
      <ButtonTheme color={salesDoc.template.showHideDetailsButtonsColor}>
        <Button
          actionStandard
          text={`${showItemDetails ? 'Hide' : 'Show'} Details`}
          onClick={handleShowDetails}

        />
      </ButtonTheme>
      <Collapse in={showItemDetails}>
        <BodyText>
          {variantDetailsText}
          {decorationDetailsText}
        </BodyText>
      </Collapse>
    </>
  );
};

const ShoppingCartFooterSection = ({onClickNext}) => {
  const {salesDoc, cartDoc, setCartOpen} = usePageContext();
  const hasItemsInCart = cartDoc.getGroupIds().map((groupId) => cartDoc.getFirstProductVariantItemInGroup(groupId)).filter(Boolean).length > 0;

  return (
    <ToolboxSection stickyFooter className={'shopping-cart-footer'}>

      <div className={'shopping-cart-totals'}>
        <div>
          <Table>
            <TableRow>
              <TableCell text={'Subtotal:'} />
              <TableCell>
                <BodyText dark className={'currency-prefix'}>{asCurrencyStringCommaSeparated(cartDoc.summary.subTotal)}</BodyText>
              </TableCell>
            </TableRow>
          </Table>
        </div>
        <BodyText descriptive>Taxes calculated at checkout</BodyText>
      </div>

      <Row divider />

      <Row justifySpaceBetween gap className={'shopping-cart-action-buttons'}>
        <ButtonTheme color={salesDoc.template?.continueShoppingButtonColor}>
          <Button navStandard text={salesDoc.template.continueShoppingButtonText} caps={false} onClick={() => setCartOpen(false)} />
        </ButtonTheme>
        <ButtonTheme color={salesDoc.template.checkOutButtonColor}>
          <Button navMain text={salesDoc.template.checkOutButtonText} caps={false} onClick={onClickNext} disabled={!hasItemsInCart} />
        </ButtonTheme>
      </Row>

    </ToolboxSection >
  );
};

const AddToCartSection = () => {
  const {cartDoc: mainCartDoc, salesDoc, selection, setCartOpen, setSelection} = usePageContext();
  const selectedItem = mainCartDoc.getItem(selection.itemId);
  const {cartDoc, addItemToCart, cartItemMap, transferToCart} = useSalesDocCartState({salesStore: salesDoc, cart: mainCartDoc, groupId: selectedItem?.groupId ?? selection.groupId});
  const [showAllTableRows, setShowAllTableRows] = useState(true);

  const isEditingCart = !!selectedItem;

  const storeVariantId = selection.variantId;
  const storeGroupId = selection.groupId;
  const cartVariantId = cartItemMap.getCartId(selection.variantId);
  const cartGroupId = cartItemMap.getCartId(selection.groupId);

  const variantItemsInCart = cartDoc?.getVariantItems(cartVariantId);
  const includedAddOnsInCart = cartDoc?.getItemsInGroup(cartGroupId).filter((item) => ((item.isDecoration() || item.isAdditionalCost()) && item.rollupSellPrice && item.showItem));
  const availableAddOnsInCart = cartDoc?.getItemsInGroup(cartGroupId).filter((item) => ((item.isDecoration() || item.isAdditionalCost()) && !item.rollupSellPrice && item.showItem));

  const variantItemsInStore = salesDoc.getVariantItems(storeVariantId);
  const firstVariantInStore = variantItemsInStore[0];
  const variantTableSizeColumns = firstVariantInStore.sizes ?? ['One Size'];
  const variantTableColorRows = (showAllTableRows ? firstVariantInStore?.colors : firstVariantInStore?.colors.filter((color) => variantItemsInCart?.some((item) => item.color === color))) ?? ['One Color'];

  const includedAddOns = salesDoc.getItemsInGroup(storeGroupId).filter((item) => ((item.isDecoration() || item.isAdditionalCost()) && item.rollupSellPrice && item.showItem));
  const availableAddOns = salesDoc.getItemsInGroup(storeGroupId).filter((item) => ((item.isDecoration() || item.isAdditionalCost()) && !item.rollupSellPrice && item.showItem));

  const variantQuantityMin = firstVariantInStore?.quantity;
  const cartQuantityTotal = cartDoc?.summary?.quantities[cartGroupId]?.quantity;

  const handleAddToCart = useCallback(() => {
    transferToCart(mainCartDoc);
    setSelection({});
  }, [transferToCart, mainCartDoc, setSelection]);

  const handleUpsertVariantToCart = useCallback(({e, color, size}) => {
    const quantity = valueFromEvent(e);
    const variant = variantItemsInCart.find((v) => v.color === color && v.size === size);
    if (variant) {
      variant.setQuantity(quantity);
    } else if (quantity > 0) {
      addItemToCart({item: firstVariantInStore, color, size, quantity});
    }
  }, [addItemToCart, firstVariantInStore, variantItemsInCart]);

  const handleToggleAddOnsToCart = useCallback((item) => {
    const tempCartItem = cartDoc.items.find((ci) => ci._id === item._id);
    if (tempCartItem) {
      tempCartItem.delete();
    } else {
      addItemToCart({item});
    }
  }, [addItemToCart, cartDoc]);

  return (
    <>
      <ToolboxSection noSep className={'shopping-cart-content'} closable heading={isEditingCart ? 'Update Cart' : 'Add to Cart'} underSep onClose={() => setCartOpen(false)}>
        {cartDoc && firstVariantInStore &&
          <>

            {firstVariantInStore &&
              // Variant Quantity Table
              <>
                <Column gap>
                  <HeadingText x24 dark text={firstVariantInStore.name} />
                  <BodyText dark text={'Choose Your Variants'} />
                </Column>
                <Column gap className={'variant-table-column'}>
                  <Table bodyBorder className={'variant-table'}>
                    <TableRow>
                      <TableCell>
                        <BodyText dark>Colors</BodyText>
                      </TableCell>
                      {variantTableSizeColumns.map((size) =>
                        <TableCell key={size} text={size} alignRight />
                      )}
                      <TableCell>
                        <BodyText dark>Sub Total</BodyText>
                      </TableCell>
                    </TableRow>
                    {variantTableColorRows.map((color) => (
                      <TableRow key={color}>
                        <TableCell text={color} />
                        {variantTableSizeColumns.map((size) =>
                          <TableCell key={size} alignRight>
                            <TextInput
                              type={'number'}
                              value={variantItemsInCart.find((v) => v.color === color && v.size === size)?.quantity ?? ''}
                              onChange={(e) => handleUpsertVariantToCart({e, color, size})}
                            />
                          </TableCell>
                        )}
                        <TableCell alignRight>
                          <BodyText>{cartDoc.getQuantityByColor(color) || ''}</BodyText>
                        </TableCell>
                      </TableRow>
                    )
                    )}
                    <TableRow>
                      <TableCell>
                        <BodyText dark>Sub Total</BodyText>
                      </TableCell>
                      {variantTableSizeColumns.map((size) => (
                        <TableCell key={size} alignRight>
                          <BodyText>{cartDoc.getQuantityBySize(size) || ''}</BodyText>
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                    <TableRow className={'total-row'}>
                      <TableCell colSpan={2 + variantTableSizeColumns?.length}>
                        <Row justifySpaceBetween>
                          <BodyText><b>Total</b> {firstVariantInStore.quantity > 1 && `(Minimum Order ${firstVariantInStore.quantity} Units)`}</BodyText>
                          <BodyText>{cartDoc.summary.getGroupSummary(cartGroupId)?.quantity}</BodyText>
                        </Row>
                      </TableCell>
                    </TableRow>
                  </Table>
                </Column>
              </>
            }

            <Row gap className={'show-hide-details-buttons'}>
              <ButtonTheme color={salesDoc.template.showHideDetailsButtonsColor}>
                <Button actionStandard text={'Show All'} onClick={() => setShowAllTableRows(true)} />
              </ButtonTheme>
              <ButtonTheme color={salesDoc.template.showHideDetailsButtonsColor}>
                <Button actionStandard text={'Show Only My Selections'} onClick={() => setShowAllTableRows(false)} />
              </ButtonTheme>
            </Row>

            <Column gap>
              {(includedAddOns.length > 0 || availableAddOns.length > 0) &&
                <>
                  <Row divider />
                  <HeadingText x16 dark text={'Add Ons'} />
                  {includedAddOns.length > 0 &&
                    <Column>
                      <BodyText dark text={'Included Add-Ons'} />
                      <AddOnsChipListSelectable items={groupBy(includedAddOns, (item) => item.variantId)} selectedItems={includedAddOnsInCart} setSelected={handleToggleAddOnsToCart} />
                    </Column>
                  }
                  {availableAddOns.length > 0 &&
                    <Column>
                      <BodyText dark text={'Other Available Add-Ons'} />
                      <AddOnsChipListSelectable items={groupBy(availableAddOns, (item) => item.variantId)} selectedItems={availableAddOnsInCart} setSelected={handleToggleAddOnsToCart} />
                    </Column>
                  }
                </>
              }
            </Column>
          </>
        }
      </ToolboxSection>
      <ToolboxSection stickyFooter className={'shopping-cart-footer'}>

        <div className={'shopping-cart-totals'}>
          <div>
            <Table>
              <TableRow>
                <TableCell text={'Subtotal:'} />
                <TableCell>
                  <BodyText dark className={'currency-prefix'}>{asCurrencyStringCommaSeparated(cartDoc?.summary?.summary[cartGroupId]?.totalRevenue ?? 0)}</BodyText>
                </TableCell>
              </TableRow>
            </Table>
          </div>
          <BodyText descriptive>Taxes calculated at checkout</BodyText>
        </div>

        <Row divider />

        {firstVariantInStore &&
          <Row justifySpaceBetween gap className={'shopping-cart-action-buttons'}>
            <ToolTip tip={'You have not met the minimum order quantity for this product. Please adjust your quantities to continue.'} placement={Placement.Left} disabled={cartQuantityTotal && variantQuantityMin < cartQuantityTotal} >
              {!isEditingCart &&
                <ButtonTheme color={salesDoc.template.cartButtonColor}>
                  <Button navMain text={salesDoc.template.cartButtonText} caps={false} onClick={handleAddToCart} disabled={!cartQuantityTotal || variantQuantityMin > cartQuantityTotal} />
                </ButtonTheme>
              }
              {isEditingCart &&
                <ButtonTheme color={salesDoc.template.updateCartButtonColor}>
                  <Button navMain text={salesDoc.template.updateCartButtonText} caps={false} onClick={handleAddToCart} disabled={!cartQuantityTotal || variantQuantityMin > cartQuantityTotal} />
                </ButtonTheme>
              }
            </ToolTip>
          </Row>
        }

      </ToolboxSection>
    </>
  );
};

const AddOnsChipListSelectable = ({items, selectedItems, setSelected}) => (
  <Row wrap className={'chip-container'}>
    {Object.entries(items).map(([key, item]) => {
      const tip = `${item.position ? `position: ${item.position}\n` : ''}${item.color ? `color: ${item.color}\n` : ''}${item.size ? `size: ${item.size}\n` : ''}`;
      const selected = selectedItems?.some((selectedItem) => item.some(({_id}) => _id === selectedItem._id));
      const firstItem = Array.isArray(item) ? item[0] : item;
      return (
        <Chip
          key={key}
          onClick={() => setSelected(firstItem)}
          className={['selectable-chip', selected && 'selected', (firstItem.isDecorationSetupCost() || firstItem.rollupSellPrice) && 'disabled']}
        >
          {stripHTML(firstItem.description)}
          {tip.length > 0 &&
            <ToolTip top tip={<div style={{whiteSpace: 'pre-line'}}>{tip}</div>}>
              <InfoIcon fontSize='small' />
            </ToolTip>
          }
        </Chip>
      );
    })
    }
  </Row>
);

const ShopperDetailsSection = ({onClickBack, onClickNext}) => {
  const {cartDoc, salesDoc, setCartOpen} = usePageContext();
  const [useDifferentBillingAddress, setUseDifferentBillingAddress] = useState(!isEqual(cartDoc.shippingAddress, cartDoc.billingAddress));
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(cartDoc.shopper?.firstName && cartDoc.shopper?.lastName && cartDoc.shopper?.email && isValidField('email'));
  }, [cartDoc.shopper]);

  const handleShopperChangeField = useCallback((e) => {
    cartDoc.setShopper({...cartDoc.shopper, [inputNameFromEvent(e)]: valueFromEvent(e)});
  }, [cartDoc]);

  const handleChangeShippingAddressField = useCallback((e) => {
    const newCartDoc = cartDoc.setShippingAddress({...cartDoc.shippingAddress, [inputNameFromEvent(e)]: valueFromEvent(e)});
    // When "Use a Different Billing Address" is selected, set cartDoc billing address with the shipping address data
    if (!useDifferentBillingAddress) {
      newCartDoc.setBillingAddress(newCartDoc.shippingAddress);
    }
  }, [cartDoc, useDifferentBillingAddress]);

  const handleChangeBillingAddressField = useCallback((e) => {
    cartDoc.setBillingAddress({...cartDoc.billingAddress, [inputNameFromEvent(e)]: valueFromEvent(e)});
  }, [cartDoc]);

  const handleToggleBillingAddress = useCallback((e) => {
    setUseDifferentBillingAddress(boolValueFromEvent(e));
    // When "Use a Different Billing Address" is clicked, initially populate billing address with the shipping address data
    if (useDifferentBillingAddress) {
      cartDoc.setBillingAddress(cartDoc.shippingAddress);
    }
  }, [cartDoc, setUseDifferentBillingAddress, useDifferentBillingAddress]);

  return (
    <>
      <ToolboxSection noSep className={'shopper-edit-details'} closable heading={'Your Details'} underSep onClose={() => setCartOpen(false)}>
        <HeadingText x24 text={'Contact'} className={'field-heading'} />
        <Fields columns={3}>
          <Field>
            <CaptionText>First Name *</CaptionText>
            <TextInput name={'firstName'} value={cartDoc.shopper?.firstName} onChange={handleShopperChangeField} />
          </Field>
          <Field>
            <CaptionText>Last Name *</CaptionText>
            <TextInput name={'lastName'} value={cartDoc.shopper?.lastName} onChange={handleShopperChangeField} />
          </Field>
          <Field>
            <CaptionText>Company Name</CaptionText>
            <TextInput name={'company'} value={cartDoc.shopper?.company} onChange={handleShopperChangeField} />
          </Field>
        </Fields>
        <Fields columns={2}>
          <Field>
            <CaptionText>Phone</CaptionText>
            <TextInput name={'phone'} value={cartDoc.shopper?.phone} onChange={handleShopperChangeField} />
          </Field>
          <Field>
            <CaptionText>Email *</CaptionText>
            <TextInput name={'email'} type={'email'} value={cartDoc.shopper?.email} onChange={handleShopperChangeField} />
          </Field>
        </Fields>

        <HeadingText x24 text={'Shipping'} className={'field-heading'} />
        <Fields columns={1}>
          <Field>
            <CaptionText>Address Line 1</CaptionText>
            <TextInput name={'address1'} value={cartDoc.shippingAddress?.address1} onChange={handleChangeShippingAddressField} />
          </Field>
          <Field>
            <CaptionText>Address Line 2</CaptionText>
            <TextInput name={'address2'} value={cartDoc.shippingAddress?.address2} onChange={handleChangeShippingAddressField} />
          </Field>
        </Fields>
        <Fields columns={2}>
          <Field>
            <CaptionText>City</CaptionText>
            <TextInput name={'city'} value={cartDoc.shippingAddress?.city} onChange={handleChangeShippingAddressField} />
          </Field>
          <Field>
            <CaptionText>State</CaptionText>
            <TextInput name={'state'} value={cartDoc.shippingAddress?.state} onChange={handleChangeShippingAddressField} />
          </Field>
          <Field>
            <CaptionText>Postcode</CaptionText>
            <TextInput name={'postcode'} value={cartDoc.shippingAddress?.postcode} onChange={handleChangeShippingAddressField} />
          </Field>
          <Field>
            <CaptionText>Country</CaptionText>
            <TextInput name={'country'} value={cartDoc.shippingAddress?.country} onChange={handleChangeShippingAddressField} />
          </Field>
        </Fields>

        <HeadingText x24 text={'Billing'} className={'field-heading'} />
        <DescriptiveRadioGroup value={useDifferentBillingAddress} onChange={handleToggleBillingAddress}>
          <DescriptiveRadio caption={'Same as Shipping Address'} value={false} />
          <DescriptiveRadio caption={'Use a Different Billing Address'} value={true} />
        </DescriptiveRadioGroup>
        <BodyText>{useDifferentBillingAddress}</BodyText>
        <Collapse in={useDifferentBillingAddress}>
          <Fields columns={1}>
            <Field>
              <CaptionText>Address Line 1</CaptionText>
              <TextInput name={'address1'} value={cartDoc.billingAddress?.address1} onChange={handleChangeBillingAddressField} />
            </Field>
            <Field>
              <CaptionText>Address Line 2</CaptionText>
              <TextInput name={'address2'} value={cartDoc.billingAddress?.address2} onChange={handleChangeBillingAddressField} />
            </Field>
          </Fields>
          <Fields columns={2}>
            <Field>
              <CaptionText>City</CaptionText>
              <TextInput name={'city'} value={cartDoc.billingAddress?.city} onChange={handleChangeBillingAddressField} />
            </Field>
            <Field>
              <CaptionText>State</CaptionText>
              <TextInput name={'state'} value={cartDoc.billingAddress?.state} onChange={handleChangeBillingAddressField} />
            </Field>
            <Field>
              <CaptionText>Postcode</CaptionText>
              <TextInput name={'postcode'} value={cartDoc.billingAddress?.postcode} onChange={handleChangeBillingAddressField} />
            </Field>
            <Field>
              <CaptionText>Country</CaptionText>
              <TextInput name={'country'} value={cartDoc.billingAddress?.country} onChange={handleChangeBillingAddressField} />
            </Field>
          </Fields>
        </Collapse>
      </ToolboxSection>

      <ToolboxSection stickyFooter className={'shopping-cart-footer'}>
        <Row justifySpaceBetween gap className={'shopping-cart-action-buttons'}>
          <ButtonTheme color={salesDoc.template?.backButtonColor}>
            <Button navStandard text={salesDoc.template.backButtonText} caps={false} onClick={onClickBack} />
          </ButtonTheme>
          <ButtonTheme color={salesDoc.template?.nextButtonColor}>
            <Button navMain text={salesDoc.template.nextButtonText} caps={false} onClick={onClickNext} disabled={!isValid} />
          </ButtonTheme>
        </Row>
      </ToolboxSection >
    </>

  );
};

const UpdateFilesAndNotesSection = ({onClickBack, onClickNext}) => {
  const {cartDoc, salesDoc, salesDocToken, uploadCache, setCartOpen} = usePageContext();
  const {associateFiles: associateFilesApi} = useSalesDocAssociateFiles();
  const [saving, setSaving] = useState(false);

  const shopper = cartDoc.customerId ? cartDoc.contact : cartDoc.shopper;
  const billingAddress = formatAddressTwoLines(cartDoc.billingAddress);
  const shippingAddress = formatAddressTwoLines(cartDoc.shippingAddress);

  const files = uploadCache.files.filter((file) => file.url).map((file) => ({url: file.url, name: file.name, uuid: file.uuid}));

  const {commentText, setCommentText, saveComment} = useComment({id: salesDocToken, successMessage: false});

  const handleDeleteFile = useCallback((file) => {
    uploadCache.deleteFile(file);
  }, [uploadCache]);

  const handleClickNext = useCallback(async () => {
    setSaving(true);
    if (uploadCache.files.length > 0) {
      await associateFilesApi({
        id: salesDocToken,
        files: uploadCache.files.map((file) => ({
          key: file.uuid,
          filename: file.name,
          shortUrl: file.name,
          url: file.url,
          isWorkable: true,
          contentType: file.type,
          bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        }
        ))
      }, {successMessage: false, errorMessage: ({message}) => message});
    }
    if (commentText.length > 0) {
      await saveComment();
    }
    setSaving(false);
    onClickNext();
  }, [commentText, onClickNext, salesDocToken, saveComment, associateFilesApi, uploadCache]);

  return (
    <>
      <ToolboxSection noSep closable heading={'Your Details'} underSep onClose={() => setCartOpen(false)}>
        <Column gap>
          <Row gap className={'shopper-details'}>
            <Column fillWidth>
              <HeadingText x20>{cartDoc.customerId ? cartDoc.customer.name : shopper.company}</HeadingText>
              <BodyText>{fullName(shopper)}</BodyText>
              <BodyText>{shopper.phone}</BodyText>
              <BodyText>{shopper.email}</BodyText>
            </Column>
            <Column fillWidth className={'shopper-details-address-column'}>
              {cartDoc.billingAddress &&
                <>
                  <CaptionText>Billing Address</CaptionText>
                  <BodyText>{billingAddress.line1}</BodyText>
                  <BodyText>{billingAddress.line2}</BodyText>
                </>
              }
            </Column>
            <Column fillWidth className={'shopper-details-address-column'}>
              {cartDoc.shippingAddress &&
                <>
                  <CaptionText>Shipping Address</CaptionText>
                  <BodyText>{shippingAddress.line1}</BodyText>
                  <BodyText>{shippingAddress.line2}</BodyText>
                </>
              }
            </Column>
          </Row>

          <Row divider />

          <Column>
            <HeadingText x20 text={'Update Files / Artwork'} />
            <ImageAndFileUploadManager
              acceptAny
              acceptPDF
              dropZoneFirst
              files={[]}
              value={''}
              maxSize={MaxFileSize.Max200Meg}
              uploadCache={uploadCache}
            />
            {files.map((file) => (
              <Row key={file.uuid} gap alignCenter className={'shopper-uploaded-image'}>
                <ImageThumbnail
                  imageUrl={file.url}
                  hoverZoom
                  size={THUMBNAIL_SIZE}
                />
                <BodyText text={file.name} />
                <DeleteIcon onClick={() => handleDeleteFile(file)} />
              </Row>
            ))}
          </Column>

          <Row divider />

          <Column>
            <HeadingText x20 text={'Order Notes'} />
            <TextInput rows={5} value={commentText} multiline onChange={(e) => setCommentText(valueFromEvent(e))} />
          </Column>
        </Column>
      </ToolboxSection>

      <ToolboxSection stickyFooter className={'shopping-cart-footer'}>
        <Row justifySpaceBetween gap className={'shopping-cart-action-buttons'}>
          <ButtonTheme color={salesDoc.template?.backButtonColor}>
            <Button navStandard text={salesDoc.template.backButtonText} caps={false} onClick={onClickBack} />
          </ButtonTheme>
          <ButtonTheme color={salesDoc.template?.nextButtonColor}>
            <Button navMain text={salesDoc.template.nextButtonText} caps={false} loading={saving} onClick={handleClickNext} />
          </ButtonTheme>
        </Row>
      </ToolboxSection >
    </>

  );
};

const CompleteOrderFooterSection = ({onClickBack, onClickPayLater, onClickPayNow}) => {
  const {salesDoc, company} = usePageContext();

  return (
    <ToolboxSection noSep stickyFooter className={'shopping-cart-footer'}>

      <Row divider />
      <Row justifySpaceBetween gap className={'shopping-cart-action-buttons'}>
        <ButtonTheme color={salesDoc.template?.backButtonColor}>
          <Button navStandard text={salesDoc.template.backButtonText} caps={false} onClick={onClickBack} />
        </ButtonTheme>
        {(salesDoc.template.payLaterButtonEnabled || !company.stripeConnectedAccountId) &&
          <ButtonTheme color={salesDoc.template?.payLaterButtonColor}>
            <Button navMain text={salesDoc.template.payLaterButtonText} caps={false} onClick={onClickPayLater} />
          </ButtonTheme>
        }
        {(salesDoc.template.payNowButtonEnabled && company.stripeConnectedAccountId) &&
          <ButtonTheme color={salesDoc.template.payNowButtonColor}>
            <Button navMain text={salesDoc.template.payNowButtonText} caps={false} onClick={onClickPayNow} disabled={!company.stripeConnectedAccountId} />
          </ButtonTheme>
        }
      </Row>

    </ToolboxSection >
  );
};

const successLottie = {
  loop: false,
  autoplay: true,
  animationData: successAlertIcon,
  rendererSettings: {preserveAspectRatio: 'xMidYMid slice'}
};

const PayNowSection = ({onClickBack}) => {
  const {cartDoc, company, salesDoc, salesDocToken, setCartOpen, setSelection} = usePageContext();

  const stripeRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const handleSuccessfulPayment = useCallback(() => {
    setIsLoading(false);
    setSelection((prev) => ({...prev, subSection: CartSubSections.orderConfirmed}));
  }, [setSelection]);

  const handlePayOnline = useCallback(() => {
    setIsLoading(true);
    stripeRef.current.performPayment();
  }, []);

  const handleError = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      <ToolboxSection noSep closable heading={'Payment'} underSep onClose={() => setCartOpen(false)} className={'pay-online-section'}>
        <PayOnline
          amountToPay={cartDoc.total + cartDoc.summary.checkoutExtras.total}
          companyName={company.name}
          feesAndCharges={cartDoc.getItemsAtCheckout().map((item) => ({name: item.name, amount: cartDoc.summary.getItemTotalIncTax(item)}))}
          hidePayButton={true}
          invoiceAmount={cartDoc.total}
          onSuccess={handleSuccessfulPayment}
          onError={handleError}
          ref={stripeRef}
          salesDocToken={salesDocToken}
          stripeConnectedAccount={company.stripeConnectedAccountId}
        />
      </ToolboxSection>

      <ToolboxSection noSep stickyFooter className={'shopping-cart-footer'}>
        <Row divider />
        <Row justifySpaceBetween gap className={'shopping-cart-action-buttons'}>
          <ButtonTheme color={salesDoc.template?.backButtonColor}>
            <Button navStandard text={salesDoc.template.backButtonText} caps={false} onClick={onClickBack} />
          </ButtonTheme>
          <ButtonTheme color={salesDoc.template.processPaymentButtonColor}>
            <Button
              disabled={stripeRef.current?.canPay()}
              text={salesDoc.template.processPaymentButtonText}
              navMain
              loading={isLoading}
              caps={false}
              noWrap
              onClick={handlePayOnline}
            />
          </ButtonTheme>
        </Row>
      </ToolboxSection >
    </>
  );
};

const OrderConfirmedSection = () => {
  const {cartDoc, setCartOpen} = usePageContext();

  const shopperFirstName = cartDoc.customerId ? cartDoc.contact.firstName : cartDoc.shopper.firstName;

  return (
    <ToolboxSection noSep className={'order-confirmation-success'} closable heading={'Order Confirmed'} underSep onClose={() => setCartOpen(false)}>
      <Row>
        <Column>
          <Lottie
            options={successLottie}
            height={100}
            width={100}
          />
        </Column>
        <Column justifyCenter>
          <HeadingText x24 dark text={`Thank you${shopperFirstName ? ` ${shopperFirstName}` : ''}!`} />
          <BodyText text={`Order # ${cartDoc.number}`} />
        </Column>
      </Row>
      <BodyText text={'You’ll receive updates as your order progresses'} />
      <Row divider />
    </ToolboxSection>
  );
};
