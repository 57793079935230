import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {useHistory} from 'react-router-dom';
import {Info as InfoIcon} from '@mui/icons-material';
import {AutoSaveIndicator} from '../../../componentsLib/AutoSave';
import {PageHeader} from '../../../componentsLib/Banners';
import {BusySpinner, Button, InfoNotice, Link, Switch, ToolTip} from '../../../componentsLib/Basic';
import {Page, Row} from '../../../componentsLib/Layout';
import {Placement, PopupItem} from '../../../componentsLib/Popovers';
import {useCompany, useLocalStorageState, useMountEffect, useRefreshHint, useS3UploadCache} from '../../../hooks';
import {registerGlobalStyle} from '../../../theme';
import {SalesDoc, SalesDocItem} from '../Models/SalesDoc';
import {SalesDocSections} from '../Components/SalesDocSection';
import {SalesDocToolboxDrawer} from '../Toolboxes/SalesDocToolboxDrawer';
import {useSalesDocState} from '../State/useSalesDocState';
import {PresentationView} from '../Views/PresentationView';
import {SalesDocView} from '../Views/SalesDocView';
import {SendSalesDocModal} from '../../../componentsHoops/Modals/SendSalesDocModal';
import {asCurrencyRounded, formatDateTimeRelative} from '../../../utils';
import {useGetIntegration, useGetJob, useGetViews} from '../../../hooks/api';
import {getInvoice} from '../../../actions/invoice';
import {useDispatch} from 'react-redux';

registerGlobalStyle('.salesdoc-builder-page', (theme) => ({
  '.loading-row': {width: '100%', height: '100%'},
  '&.presentation': {background: theme.colors.background.white},
  '.salesdoc-container': {
    height: '100%',
    '.left-side-cover': {
      position: 'fixed',
      width: '100%',
      height: '100%',
      background: theme.colors.background.grey.dark,
      transition: 'all .1s linear allow-discrete',
      opacity: 0.3,
      display: 'block',
    },
  },
  '.info-icon': {color: theme.colors.text.mediumIcon},
}));

const SALESDOC_TOOLBOX_WIDTH = 800;

export const SalesDocBuilderPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {templateId: _templateId, draftId: _autoSaveKey, salesDocNumber, revisionId, action} = useParams();
  const [refreshHint, setRefreshHint] = useRefreshHint('/sales/new', {});
  const [templateId] = useState(_templateId ?? refreshHint?.templateId);
  const [autoSaveKey] = useState(_autoSaveKey ?? refreshHint?.autoSaveKey);
  const {
    autoSaveCreatedAt,
    autoSaveKey: savedAutoSaveKey,
    changed: salesDocChanged,
    createSalesDoc,
    deleteAutoSave,
    salesDoc,
    saveSalesDoc,
    loading,
  } = useSalesDocState({salesDocNumber, revisionId, templateId, autoSaveKey, action});
  const {data: {views}} = useGetViews('salesdocs');
  const [showSend, setShowSend] = useState(false);
  const [goToInvoiceAfterSend, setGoToInvoiceAfterSend] = useState(false);
  const [selection, setSelection] = useState({section: SalesDocSections.documentHeader});
  const {company} = useCompany(salesDoc?.companyTradingEntityId ?? salesDoc?.customer?.companyTradingEntityId);
  const [allCategories, setAllCategories] = useState();
  const [contactSearch, setContactSearch] = useState('');
  const [updateJobOnSave, setUpdateJobOnSave] = useLocalStorageState('salesDoc|updateJobOnSave', false);
  const [updateInvoiceOnSave, setUpdateInvoiceOnSave] = useLocalStorageState('salesDoc|updateInvoiceOnSave', false);
  const uploadCache = useS3UploadCache();
  const {data: job} = useGetJob(salesDoc?.jobId);
  const [toolboxOpen, setToolboxOpen] = useState(true);
  const [zoomProps, setZoomProps] = useState({zoom: 100, translateX: 0});
  const [autoZoom, _setAutoZoom] = useLocalStorageState('salesdoc|builder|autoZoom', false);
  const zoomRef = useRef(null);
  const {data: {integration: sageIntegration}} = useGetIntegration('sage');

  // If job is marked as complete prevent job from being updated
  const canUpdateJob = job && !job.completed;

  // If quote is paid prevent invoice from being updated
  const canUpdateInvoice =
    salesDoc?.invoice?._id
    && (company?.accountingPlatform?.platform === 'QUICKBOOKS'
      || (company?.accountingPlatform?.platform === 'XERO'
        && asCurrencyRounded(salesDoc.invoice.balance) === asCurrencyRounded(salesDoc.invoice.totalAmount)));

  useMountEffect(() => {
    // To avoid the templateId and draftId appearing in the url we will do a little trick and
    // redirect to the url without the id. It is tidier and also avoids issues when the user
    // refreshes with the id in the url.
    const keyParts = _autoSaveKey?.split('|');
    if (_autoSaveKey && keyParts.length === 2) {
      history.replace(`/sales/${salesDoc?.documentTypeOrigin != null ? 'convert' : 'edit'}/${keyParts[1]}`);
    } else if (_autoSaveKey || _templateId) {
      setRefreshHint({autoSaveKey: _autoSaveKey, templateId: _templateId});
      history.replace('/sales/new');
    }
  });

  useEffect(() => {
    // Ensure that the url indicator of .../convert/... remains visible when reloading a draft
    if (autoSaveKey && salesDoc?.documentTypeOrigin) {
      const keyParts = autoSaveKey.split('|');
      if (keyParts.length === 2) {
        history.replace(`/sales/convert/${keyParts[1]}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesDoc?.documentTypeOrigin]);

  useEffect(() => {
    if (savedAutoSaveKey) {
      setRefreshHint({autoSaveKey: savedAutoSaveKey});
    } else if (salesDoc?.docTemplateId) {
      setRefreshHint({templateId: salesDoc?.docTemplateId});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedAutoSaveKey, salesDoc?.docTemplateId]);

  const handleClose = useCallback((goToInvoice) => {
    if (history.length <= 1) {
      history.push('/sales');
    } else {
      history.goBack();
    }
    // If we are opening the invoice, go there now. We have to also pop the history, or closing the
    // invoice will take us to the wrong place.
    if (goToInvoice === true && salesDoc.invoice?._id) {
      dispatch(getInvoice(salesDoc.invoice._id));
    }
  }, [dispatch, history, salesDoc?.invoice?._id]);

  const handleSave = useCallback(async () => {
    if (await saveSalesDoc({updateJob: canUpdateJob && updateJobOnSave, updateInvoice: canUpdateInvoice && updateInvoiceOnSave})) {
      handleClose(canUpdateInvoice && updateInvoiceOnSave);
    }
  }, [canUpdateInvoice, canUpdateJob, handleClose, saveSalesDoc, updateInvoiceOnSave, updateJobOnSave]);

  const handleQuickSave = useCallback(async () => {
    saveSalesDoc({updateJob: false, updateInvoice: false}).catch();
  }, [saveSalesDoc]);

  const handleSaveAndSend = useCallback(async () => {
    if (!salesDocChanged || (await saveSalesDoc({
      updateJob: canUpdateJob && updateJobOnSave,
      updateInvoice: canUpdateInvoice && updateInvoiceOnSave
    }))) {
      setShowSend(true);
      setGoToInvoiceAfterSend(salesDocChanged && canUpdateInvoice && updateInvoiceOnSave);
    }
  }, [canUpdateInvoice, canUpdateJob, salesDocChanged, saveSalesDoc, updateInvoiceOnSave, updateJobOnSave]);

  const handleCloseSend = useCallback((sent) => {
    setShowSend(false);
    setGoToInvoiceAfterSend(false);
    if (sent) {
      handleClose(goToInvoiceAfterSend);
    }
  }, [goToInvoiceAfterSend, handleClose]);

  const handleUpdateJobOnSave = useCallback((e) => {
    setUpdateJobOnSave(e.target.checked);
  }, [setUpdateJobOnSave]);

  const handleUpdateInvoiceOnSave = useCallback((e) => {
    setUpdateInvoiceOnSave(e.target.checked);
  }, [setUpdateInvoiceOnSave]);

  const isBuilder = true;
  const isPresentation = salesDoc?.isPresentation();

  const pageTitle = `${salesDocNumber ? 'Edit' : 'New'} ${salesDoc?.docTypeName ?? (isPresentation ? 'SalesPresentation' : 'SalesDoc')}`;

  if (allCategories == null && isPresentation && salesDoc?.docTypeName) {
    setAllCategories(salesDoc.getAllCategories());
  }

  const setZoom = useCallback((newZoom) => {
    if (zoomRef.current) {
      const paperElem = zoomRef.current.querySelector('.salesdoc-paper');
      if (paperElem) {
        const containerWidth = zoomRef.current.offsetWidth;
        const paperWidth = paperElem.offsetWidth;
        const availWidth = containerWidth - (toolboxOpen ? SALESDOC_TOOLBOX_WIDTH : 0);
        const zoomFit = 100 * (availWidth - 16) / paperWidth;
        const zoom = newZoom === 'fit' ? Math.min(100, zoomFit) : newZoom;
        const translateX = Math.max(0, (availWidth - paperWidth * zoom / 100) / 2);
        setZoomProps({zoom, translateX});
        _setAutoZoom((prev) => newZoom === 'fit' ? prev : false);
      }
    }
  }, [_setAutoZoom, toolboxOpen]);

  const setAutoZoom = useCallback((az) => {
    if (az) {
      setZoom('fit');
    }
    _setAutoZoom(az);
  }, [_setAutoZoom, setZoom]);

  useLayoutEffect(() => {
    setZoom(autoZoom ? 'fit' : 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomRef.current != null, salesDoc != null]);

  useLayoutEffect(() => {
    setZoom(autoZoom ? 'fit' : zoomProps.zoom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolboxOpen]);

  const context = useMemo(
    () => ({
      allCategories,
      company,
      contactSearch,
      createSalesDoc,
      currencySymbol: company.currencySymbol,
      isBuilder,
      loading,
      sage: sageIntegration?.status === 'active',
      salesDoc,
      salesDocChanged,
      salesDocToken: salesDoc?._id,
      selection,
      setAllCategories,
      setContactSearch,
      setSelection,
      setToolboxOpen,
      toolboxOpen,
      uploadCache,
      views,
      zoom: {
        zoom: zoomProps.zoom,
        setZoom,
        auto: autoZoom,
        setAuto: setAutoZoom,
        fitScreen: () => setZoom('fit'),
      },
    }),
    [
      allCategories,
      autoZoom,
      company,
      contactSearch,
      createSalesDoc,
      isBuilder,
      loading,
      sageIntegration?.status,
      salesDoc,
      salesDocChanged,
      selection,
      setAutoZoom,
      setZoom,
      toolboxOpen,
      uploadCache,
      views,
      zoomProps.zoom
    ]
  );

  let disableSave = false;
  let explanationDisableSave;
  if (salesDoc) {
    if (!salesDoc.docTypeName) {
      disableSave = true;
      explanationDisableSave = 'Please choose a template';
    } else if ((!salesDoc.customerId || !salesDoc.contactId) && !salesDoc.shopper && salesDoc.documentType !== SalesDoc.Type.SALESSTORE) {
      disableSave = true;
      explanationDisableSave = 'Please choose a customer and contact before saving';
    } else if (salesDoc.items.filter((item) => !item.isPlaceholder()).length === 0) {
      disableSave = true;
      explanationDisableSave = 'Please add some items to the document';
    } else if (!salesDoc.ownerId) {
      disableSave = true;
      explanationDisableSave = 'Please set the owner of the document';
    } else if (salesDoc.items.some((item) => !item.tax)) {
      disableSave = true;
      explanationDisableSave = 'Some items do not have tax applied';
    } else if (salesDoc.isPresentation()
      && salesDoc.template.payNowButtonEnabled
      && !salesDoc.template.tax
      && (salesDoc.template.checkoutDiscountEnabled || salesDoc.template.checkoutSurchargeEnabled)
    ) {
      disableSave = true;
      explanationDisableSave = 'Please select a tax option in the general settings to apply to surcharges and discounts at checkout';
    } else if (!salesDocChanged) {
      explanationDisableSave = 'There are no changes to save';
    } else if (salesDoc.isPresentation() && salesDoc.template.cartButtonEnabled && !salesDoc.template.cartTemplateId) {
      disableSave = true;
      explanationDisableSave = `Please select a SalesDoc template in the ${salesDoc.template.cartButtonText} settings`;
    } else if (
      salesDoc.isPresentation()
      && salesDoc.items.some((item) => item.type === SalesDocItem.Type.VARIANT && !item.images?.length)
    ) {
      disableSave = true;
      explanationDisableSave = 'Please ensure all products have images to display';
    }
  }

  return (
    <Page testId={'salesdoc-builder-page'} className={['salesdoc-builder-page', isPresentation && 'presentation']} context={context} title={`${pageTitle} | Hoops`}>
      <PageHeader title={pageTitle}>
        {revisionId && salesDoc &&
          <InfoNotice>
            {`You are editing an older revision of the ${salesDoc.docTypeName} updated `}
            <br />
            {`${formatDateTimeRelative(salesDoc.updatedAt)}. Saving this revision will make it the active revision.`}
          </InfoNotice>
        }
        {salesDoc && salesDoc.documentType === SalesDoc.Type.CLASSIC_QUOTE &&
          <InfoNotice>
            You are editing a classic quote. Saving it will convert it to a SalesDoc
          </InfoNotice>
        }
        <AutoSaveIndicator autoSaveCreatedAt={autoSaveCreatedAt} onDelete={deleteAutoSave} />
        {salesDocChanged && !disableSave &&
          <ToolTip
            placement={Placement.Left}
            tip={
              (canUpdateJob || canUpdateInvoice)
                ? 'Quick Save will not update your Job and/or Invoice. To update your Job/Invoice, please use one of the options in the Save menu.'
                : 'Quick Save'
            }
          >
            <Button navMain icon suffix={'save'} onClick={handleQuickSave} />
          </ToolTip>
        }
        <ToolTip tip={explanationDisableSave} placement={Placement.Left}>
          <Button
            navMain
            menu
            split
            text={salesDocChanged || disableSave ? 'Save & Send' : 'Close & Send'}
            onClick={handleSaveAndSend}
            disabled={disableSave}
            splitEnabled={disableSave ? true : undefined}
          >
            {salesDocChanged &&
              <PopupItem disabled={disableSave} text='Save & Close' onClick={handleSave} />
            }
            <PopupItem text={salesDocChanged ? 'Cancel' : 'Close'} onClick={handleClose} />
            {(job?._id || salesDoc?.invoice?._id) &&
              <PopupItem divider />
            }
            {job?._id &&
              <PopupItem keepOpen disabled={!canUpdateJob}>
                <ToolTip
                  placement={Placement.Left}
                  tip={
                    canUpdateJob
                      ? 'This will update the products, decorations and other line items on the Job. This will not update the Customer/Contact, Reference, or Deadline of the Job.'
                      : 'This Job is marked as complete and can no longer be updated.'
                  }
                >
                  <InfoIcon fontSize='small' className={'info-icon'} />
                </ToolTip>
                <Switch checked={canUpdateJob && updateJobOnSave} onChange={handleUpdateJobOnSave} disabled={!canUpdateJob}>
                  Update Job
                </Switch>
              </PopupItem>
            }
            {salesDoc?.invoice?._id &&
              <PopupItem keepOpen disabled={!canUpdateInvoice}>
                <ToolTip
                  placement={Placement.Left}
                  tip={
                    canUpdateInvoice
                      ? 'This will update the invoice line items. It will not update the Customer or the Reference.'
                      : <>
                        As there are payments applied, the invoice cannot be updated. Learn more in <Link
                          href={'https://help.xero.com/au/Payments_AR_PaidView'} underline>Xero Help</Link>.
                        To make changes, first Remove & Redo any payments and delete any credit note allocations.
                      </>
                  }
                >
                  <InfoIcon fontSize='small' className={'info-icon'} />
                </ToolTip>
                <Switch checked={canUpdateInvoice && updateInvoiceOnSave} onChange={handleUpdateInvoiceOnSave} disabled={!canUpdateInvoice}>
                  Update Invoice
                </Switch>
              </PopupItem>
            }
          </Button>
        </ToolTip>
      </PageHeader>
      <Row className={'salesdoc-container'} ref={zoomRef} style={{'--zoom': zoomProps.zoom / 100, '--zoom-x': `${zoomProps.translateX}px`}}>
        {!salesDoc && loading &&
          <Row justifyCenter alignCenter className={'loading-row'}>
            <BusySpinner large center message={'Loading'} />
          </Row>
        }
        {salesDoc && !isPresentation &&
          <SalesDocView />
        }
        {salesDoc && isPresentation &&
          <PresentationView />
        }
        {salesDoc && !salesDoc.docTemplateId &&
          <div className={'left-side-cover'} />
        }
        <SalesDocToolboxDrawer toolboxWidth={SALESDOC_TOOLBOX_WIDTH} />
      </Row>
      {showSend &&
        <SendSalesDocModal salesDoc={salesDoc} onClose={handleCloseSend} />
      }
    </Page>
  );
};
